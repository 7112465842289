import React from "react";
import classNames from "classnames"

const InputWrapper = ({children, error, containerClasses}) => (
    <div className={classNames(
        containerClasses ? containerClasses : 'form-group',
        {'has-error': !!error}
    )}>
        {children}
        {!!error && (
            <div className="validation-message">{error}</div>
        )}
    </div>
)

export default InputWrapper