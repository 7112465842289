import React from "react"
import withFieldset from "./with-fieldset";
import classNames from "classnames";
import InputWrapper from "../../elements/input-wrapper";

class Expenditure extends React.Component {

    render() {
        return (
            <div className={classNames('form-container', {active: this.props.currentStep === this.props.step})}>
                <div className="section-form-title">
                    <h4>Your expenses</h4>
                    <p className="lead">This will NOT affect your credit score</p>
                </div>
                <div className="row row-equal">
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['transport']}>
                            <label>Transport <small>EG. Fuel, Rail Costs</small></label>
                            <div className="input-group">
                                <div className="input-group-addon">
                                    <i className="fas fa-fw fa-pound-sign"></i>
                                </div>
                                <input ref={this.props.createInputRef.bind(null, 'transport')} type="text" className="form-control numbers-only"/>
                            </div>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['loans']}>
                            <label>Monthly Credit Commitments <small>Include all monthly commitments</small></label>
                            <div className="input-group">
                                <div className="input-group-addon">
                                    <i className="fas fa-fw fa-pound-sign"></i>
                                </div>
                                <input ref={this.props.createInputRef.bind(null, 'loans')} type="text" className="form-control numbers-only"/>
                            </div>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['mortgage']}>
                            <label>Mortgage / Rent <small>Your monthly mortgage / rent</small></label>
                            <div className="input-group">
                                <div className="input-group-addon">
                                    <i className="fas fa-fw fa-pound-sign"></i>
                                </div>
                                <input ref={this.props.createInputRef.bind(null, 'mortgage')} type="text" className="form-control numbers-only"/>
                            </div>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['food']}>
                            <label>Food <small>your monthly food costs</small></label>
                            <div className="input-group">
                                <div className="input-group-addon">
                                    <i className="fas fa-fw fa-pound-sign"></i>
                                </div>
                                <input ref={this.props.createInputRef.bind(null, 'food')} type="text" className="form-control numbers-only"/>
                            </div>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['utilities']}>
                            <label>Utilities <small>EG. Heating, Electricity, Gas, Phone</small></label>
                            <div className="input-group">
                                <div className="input-group-addon">
                                    <i className="fas fa-fw fa-pound-sign"></i>
                                </div>
                                <input ref={this.props.createInputRef.bind(null, 'utilities')} type="text" className="form-control numbers-only"/>
                            </div>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['expenses']}>
                            <label>Other regular expenses <small>EG. Coucil tax, childcare</small></label>
                            <div className="input-group">
                                <div className="input-group-addon">
                                    <i className="fas fa-fw fa-pound-sign"></i>
                                </div>
                                <input ref={this.props.createInputRef.bind(null, 'expenses')} type="text" className="form-control numbers-only"/>
                            </div>
                        </InputWrapper>
                    </div>
                </div>
                <div className="section-footer">
                    <a onClick={this.props.previousStep} href="" className="btn btn-primary">Previous</a>
                    <a onClick={this.props.submit} href="" className="btn btn-default">Next</a>
                </div>
            </div>
        )
    }
}

const ExpenditureFieldset = withFieldset(Expenditure)

export default ExpenditureFieldset

