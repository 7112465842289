import React from "react"
import withFieldset from "./with-fieldset";
import classNames from "classnames";
import InputWrapper from "../../elements/input-wrapper";

class Property extends React.Component {

    render() {
        return (
            <div className={classNames('form-container', {
                active: this.props.currentStep === this.props.step,
                loading: this.props.loading
            })}>
                <div className="section-form-title">
                    <h4>About you</h4>
                    <p className="lead">This will NOT affect your credit score</p>
                </div>
                <div className="row row-equal">
                    <div className="col col-xs-12">
                        <InputWrapper error={this.props.errors['property_type']}>
                            <label>Your property type</label>
                            <select ref={this.props.createInputRef.bind(null, 'property_type')} className="form-control">
                                <option>Please select</option>
                                <option>Detached</option>
                                <option>Semi Detached</option>
                                <option>Terraced</option>
                                <option>Bungalow</option>
                                <option>Apartment</option>
                                <option>Other</option>
                            </select>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12">
                        <InputWrapper error={this.props.errors['property_value']}>
                            <label>How much is your property worth?</label>
                            <input ref={this.props.createInputRef.bind(null, 'property_value')} type="text"
                                   className="form-control" />
                        </InputWrapper>
                    </div>
                </div>
                <div className="section-footer">
                    <a onClick={this.props.previousStep} href="" className="btn btn-primary">Previous</a>
                    <a onClick={this.props.submit} href="" className="btn btn-default">Next</a>
                </div>
            </div>
        )
    }
}

const PropertyFieldset = withFieldset(Property)

export default PropertyFieldset

