import React from "react"
import withFieldset from "./with-fieldset";
import classNames from "classnames";
import InputWrapper from "../../elements/input-wrapper";

class Loan extends React.Component {

    render() {
        return (
            <div className={classNames('form-container', {
                active: this.props.currentStep === this.props.step,
                loading: this.props.loading
            })}>
                <div className="section-form-title">
                    <h4>About you</h4>
                    <p className="lead">This will NOT affect your credit score</p>
                </div>
                <div className="row row-equal">
                    <div className="col col-xs-12">
                        <InputWrapper error={this.props.errors['loan_purpose']}>
                            <label>What's the reason for the loan</label>
                            <select ref={this.props.createInputRef.bind(null, 'loan_purpose')} className="form-control">
                                <option>Please select</option>
                                <option>Consolidate debts</option>
                                <option>Vehicle purchase</option>
                                <option>Home improvement</option>
                                <option>Other</option>
                            </select>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12">
                        <InputWrapper error={this.props.errors['residential_status']}>
                            <label>What's your residential status?</label>
                            <select ref={this.props.createInputRef.bind(null, 'residential_status')}
                                    className="form-control">
                                <option>Please select</option>
                                <option>Homeowner with mortgage</option>
                                <option>Outright homeowner</option>
                                <option>Tenant</option>
                                <option>Living with family</option>
                                <option>Other</option>
                            </select>
                        </InputWrapper>
                    </div>
                </div>
                <div className="section-footer">
                    <a onClick={this.props.submit} href="" className="btn btn-default">Next</a>
                </div>
            </div>
        )
    }
}

const LoanFieldset = withFieldset(Loan)

export default LoanFieldset
