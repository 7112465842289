import React from "react"
import withFieldset from "./with-fieldset";
import classNames from "classnames";
import InputWrapper from "../../elements/input-wrapper";

class Employment extends React.Component {

    render() {
        return (
            <div className={classNames('form-container', {active: this.props.currentStep === this.props.step})}>
                <div className="section-form-title">
                    <h4>Employment Status</h4>
                    <p className="lead">This will NOT affect your credit score</p>
                </div>
                <div className="row row-equal">
                    <div className="col col-xs-12">
                        <InputWrapper error={this.props.errors['employment_status']}>
                            <label>Employment status</label>
                            <select ref={this.props.createInputRef.bind(null, 'employment_status')} className="form-control">
                                <option>Please select</option>
                                <option>Full time</option>
                                <option>Self employed</option>
                                <option>Part time</option>
                                <option>Retired</option>
                                <option>Student</option>
                            </select>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['industry']}>
                            <label>Industry</label>
                            <select ref={this.props.createInputRef.bind(null, 'industry')} className="form-control">
                                <option>Please select</option>
                                <option>Accountancy</option>
                                <option>Advertising/Media</option>
                                <option>Business Consultancy</option>
                                <option>Call Centre Operations</option>
                                <option>Cleaning Services</option>
                                <option>Computer Services</option>
                                <option>Construction</option>
                                <option>Education</option>
                                <option>Electricity/Gas/Water</option>
                                <option>Finance</option>
                                <option>Health</option>
                                <option>Hotels/Restaurants</option>
                                <option>Insurance</option>
                                <option>Legal Services</option>
                                <option>Leisure/Culture</option>
                                <option>Manufacturing</option>
                                <option>Military</option>
                                <option>Mining/Quarrying</option>
                                <option>Public Administration</option>
                                <option>Publishing</option>
                                <option>Real Estate/Property</option>
                                <option>Research/Development</option>
                                <option>Retail</option>
                                <option>Telecoms/Internet</option>
                                <option>Transportation</option>
                                <option>Other</option>
                            </select>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['employer_name']}>
                            <label>Employer</label>
                            <input ref={this.props.createInputRef.bind(null, 'employer_name')} type="text"
                                   className="form-control"/>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['yearly_income']}>
                            <label>Yearly income before tax</label>
                            <div className="input-group">
                                <div className="input-group-addon">
                                    <i className="fas fa-fw fa-pound-sign"></i>
                                </div>
                                <input ref={this.props.createInputRef.bind(null, 'yearly_income')} type="text"
                                       className="form-control"/>
                            </div>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['income_frequency']}>
                            <label>Income frequency</label>
                            <select className="form-control" ref={this.props.createInputRef.bind(null, 'income_frequency')}>
                                <option>Please select</option>
                                <option>Specific Day of the Month</option>
                                <option>Last Working Day of the Month</option>
                                <option>Last Monday of the Month</option>
                                <option>Last Tuesday of the Month</option>
                                <option>Last Wednesday of the Month</option>
                                <option>Last Thursday of the Month</option>
                                <option>Last Friday of the Month</option>
                                <option>Four Weekly</option>
                                <option>Twice Monthly</option>
                                <option>Bi-Weekly</option>
                                <option>Weekly</option>
                                <option>Every 2 weeks</option>
                                <option>Twice a month</option>
                                <option>Monthly</option>
                            </select>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12">
                        <InputWrapper error={this.props.errors['payment_type']}>
                            <label>Payment Type</label>
                            <select className="form-control" ref={this.props.createInputRef.bind(null, 'payment_type')}>
                                <option>Please select</option>
                                <option>Electronically</option>
                                <option>Cash</option>
                                <option>Other</option>
                            </select>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['payment_date']}>
                            <label>Payment date</label>
                            <div className="form-choice">
                                <div className="field dob-day">
                                    <input ref={this.props.createInputRef.bind(null, 'payment_date_day')} type="text"
                                           className="form-control" placeholder="DD"
                                           maxLength="2"/>
                                </div>
                                <div className="field dob-month">
                                    <input ref={this.props.createInputRef.bind(null, 'payment_date_month')} type="text"
                                           className="form-control" placeholder="MM"
                                           maxLength="2"/>
                                </div>
                                <div className="field dob-year">
                                    <input ref={this.props.createInputRef.bind(null, 'payment_date_year')} type="text"
                                           className="form-control" placeholder="YYYY"
                                           maxLength="4"/>
                                </div>
                            </div>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['f_payment_date']}>
                            <label>Following payment date</label>
                            <div className="form-choice">
                                <div className="field dob-day">
                                    <input ref={this.props.createInputRef.bind(null, 'f_payment_date_day')} type="text"
                                           className="form-control" placeholder="DD"
                                           maxLength="2"/>
                                </div>
                                <div className="field dob-month">
                                    <input ref={this.props.createInputRef.bind(null, 'f_payment_date_month')} type="text"
                                           className="form-control" placeholder="MM"
                                           maxLength="2"/>
                                </div>
                                <div className="field dob-year">
                                    <input ref={this.props.createInputRef.bind(null, 'f_payment_date_year')} type="text"
                                           className="form-control" placeholder="YYYY"
                                           maxLength="4"/>
                                </div>
                            </div>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12">
                        <div className="form-group">
                            <label>Time in job</label>
                            <div className="form-choice">
                                <InputWrapper containerClasses={"field"} error={this.props.errors['time_in_job_years']}>
                                    <select ref={this.props.createInputRef.bind(null, 'time_in_job_years')}
                                            className="form-control">
                                        <option>Years</option>
                                        <option>1</option>
                                        <option>2</option>
                                    </select>
                                </InputWrapper>
                                <InputWrapper containerClasses={"field"}
                                              error={this.props.errors['time_in_job_months']}>
                                    <select ref={this.props.createInputRef.bind(null, 'time_in_job_months')}
                                            className="form-control">
                                        <option>Months</option>
                                        <option>1</option>
                                        <option>2</option>
                                    </select>
                                </InputWrapper>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-footer">
                    <a onClick={this.props.previousStep} href="" className="btn btn-primary">Previous</a>
                    <a onClick={this.props.submit} href="" className="btn btn-default">Next</a>
                </div>
            </div>
        )
    }
}

const EmploymentFieldset = withFieldset(Employment)

export default EmploymentFieldset

