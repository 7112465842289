import React from "react"
import classNames from "classnames";
import withFieldset from "./with-fieldset";
import InputWrapper from "../../elements/input-wrapper";

class Personal extends React.Component {

    render() {
        return (
            <div className={classNames('form-container', {
                active: this.props.currentStep === this.props.step,
                loading: this.props.loading
            })}>
                <div className="section-form-title">
                    <h4>About you</h4>
                    <p className="lead">This will NOT affect your credit score</p>
                </div>
                <div className="row row-equal">
                    <div className="col col-xs-12">
                        <InputWrapper error={this.props.errors['marital_status']}>
                            <label>What's your relationship status?</label>
                            <select ref={this.props.createInputRef.bind(null, 'marital_status')} className="form-control">
                                <option>Please select</option>
                                <option>Single</option>
                                <option>Married / Civil partnership</option>
                                <option>Divorced</option>
                                <option>Other</option>
                            </select>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-4">
                        <InputWrapper error={this.props.errors['title']}>
                            <label>And your title?</label>
                            <select ref={this.props.createInputRef.bind(null, 'title')} className="form-control">
                                <option>Please select</option>
                                <option>Mr</option>
                                <option>Mrs</option>
                                <option>Miss</option>
                                <option>Dr</option>
                                <option>Ms</option>
                            </select>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-4">
                        <InputWrapper error={this.props.errors['firstname']}>
                            <label>First name</label>
                            <input ref={this.props.createInputRef.bind(null, 'firstname')} type="text"
                                   className='form-control'/>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-4">
                        <InputWrapper error={this.props.errors['lastname']}>
                            <label>Surname</label>
                            <input ref={this.props.createInputRef.bind(null, 'lastname')} type="text"
                                   className='form-control'/>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['email']}>
                            <label>Email</label>
                            <input ref={this.props.createInputRef.bind(null, 'email')} type="text"
                                   className='form-control'/>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['mobile_telephone']}>
                            <label>Mobile Telephone</label>
                            <input ref={this.props.createInputRef.bind(null, 'mobile_telephone')} type="text"
                                   className='form-control'/>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['dob']}>
                            <label>Date of birth</label>
                            <div className="form-choice">
                                <div className="field dob-day">
                                    <input ref={this.props.createInputRef.bind(null, 'dob_day')} type="text"
                                           className="form-control" placeholder="DD"
                                           maxLength="2"/>
                                </div>
                                <div className="field dob-month">
                                    <input ref={this.props.createInputRef.bind(null, 'dob_month')} type="text"
                                           className="form-control" placeholder="MM"
                                           maxLength="2"/>
                                </div>
                                <div className="field dob-year">
                                    <input ref={this.props.createInputRef.bind(null, 'dob_year')} type="text"
                                           className="form-control" placeholder="YYYY"
                                           maxLength="4"/>
                                </div>
                            </div>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['gender']}>
                            <label>Gender</label>
                            <select ref={this.props.createInputRef.bind(null, 'gender')} className="form-control">
                                <option>Please select</option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                            </select>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12">
                        <InputWrapper error={this.props.errors['dependants']}>
                            <label>Number of dependants</label>
                            <select ref={this.props.createInputRef.bind(null, 'dependants')} className="form-control">
                                <option>Please select</option>
                                <option>0</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                            </select>
                        </InputWrapper>
                    </div>
                </div>
                <div className="section-footer">
                    <a onClick={this.props.previousStep} href="" className="btn btn-primary">Previous</a>
                    <a onClick={this.props.submit} href="" className="btn btn-default">Next</a>
                </div>
            </div>
        )
    }
}

const PersonalFieldset = withFieldset(Personal)

export default PersonalFieldset

