const getValue = Field => {
    if (!Field) {
        return null
    }
    if (Field.type === 'checkbox') {
        if (Field.checked) {
            return 1
        }
    } else {
        return Field.value
    }
}

export default getValue