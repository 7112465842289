import React from "react"
import withFieldset from "./with-fieldset";
import classNames from "classnames";
import InputWrapper from "../../elements/input-wrapper";

class BankAccount extends React.Component {

    render() {
        return (
            <div className={classNames('form-container', {active: this.props.currentStep === this.props.step})}>
                <div className="section-form-title">
                    <h4>Finally, where can the loan be paid?</h4>
                    <p className="lead">This will NOT affect your credit score</p>
                </div>
                <div className="row row-equal">
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['account_number']}>
                            <label>Account number</label>
                            <input ref={this.props.createInputRef.bind(null, 'account_number')} type="text"
                                   className="form-control numbers-only"/>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['sortcode']}>
                            <label>Sortcode</label>
                            <input ref={this.props.createInputRef.bind(null, 'sortcode')} type="text"
                                   className="form-control numbers-only"/>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['bank_card']}>
                            <label>Bank Card</label>
                            <select ref={this.props.createInputRef.bind(null, 'bank_card')} name=""
                                    className="form-control">
                                <option>Please select</option>
                                <option>Visa Debit</option>
                            </select>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <div className="form-group">
                            <label>Time at this bank</label>
                            <div className="form-choice">
                                <div className="field">
                                    <select name="" className="form-control">
                                        <option>Years</option>
                                    </select>
                                </div>
                                <div className="field">
                                    <select name="" className="form-control">
                                        <option>Months</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-xs-12">
                        <InputWrapper containerClasses={"terms-check"} error={this.props.errors['agreed_terms']}>
                            <input ref={this.props.createInputRef.bind(this, 'agreed_terms')} type="checkbox"
                                   id="termscheck"/>
                            <label htmlFor="termscheck">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam varius metus
                                quis fringilla efficitur. Aliquam quis massa a turpis placerat facilisis.
                                Pellentesque eu ullamcorper magna. Donec dolor justo, cursus eget elit eu,e
                                potenti. Aenean a feugiat sapien. Vivamus vestibulum sem at nibh fringilla
                                blandit.
                            </label>
                        </InputWrapper>
                    </div>
                </div>
                <div className="section-footer">
                    <a href="" onClick={this.props.previousStep} className="btn btn-primary">Previous</a>
                    <a href="" onClick={this.props.submitForm} className="btn btn-default">Show loan offers</a>
                </div>
            </div>

        )
    }
}

const BankAccountFieldset = withFieldset(BankAccount)

export default BankAccountFieldset