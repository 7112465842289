import React from "react"
import qs from 'qs';
import withFieldset from "./with-fieldset";
import classNames from "classnames";
import InputWrapper from "../../elements/input-wrapper";
import getValue from "../../helpers/getValue";

class Address extends React.Component {

    key = 'NC39-EC97-PZ78-MN82'

    constructor(props){
        super(props)
        this.state = {addresses: {}, selectedAddress: {}}
    }

    findAddresses(e) {
        e.preventDefault()
        const postcode = getValue(this.props.fields['postcode'])
        this.setState({addresses: {}, selectedAddress: {}})
        fetch("https://services.postcodeanywhere.co.uk/PostcodeAnywhere/Interactive/Find/v1.10/json3.ws", {
            method: "POST",
            body: qs.stringify({
                Key: this.key,
                SearchTerm: postcode,
            }),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }).then(response => {
            return response.json()
        }).then(response => {
            if(typeof(response.Items[0].Error) == "undefined") {
                this.setState({addresses: response.Items})
            }
        })
    }

    selectAddress(e) {
        const id = e.target.value
        this.setState({selectedAddress: {}})
        if (id) {
            fetch("https://services.postcodeanywhere.co.uk/PostcodeAnywhere/Interactive/RetrieveById/v1.30/json3.ws", {
                method: "POST",
                body: qs.stringify({
                    Key: this.key,
                    Id: id,
                }),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }).then(response => {
                return response.json()
            }).then(response => {
                if (response.Items.length) {
                    const address = response.Items[0]
                    this.setState({
                        selectedAddress: this.parseAddress(address)
                    })
                }
            })
        }
    }

    parseAddress(address) {
        return {
            name_or_number : address.BuildingName ? address.BuildingName : address.BuildingNumber,
            street: address.PrimaryStreet,
            city: address.PostTown,
            county: address.County,
            postcode: address.Postcode
        }
    }

    formatAddress(address) {
        return address.StreetAddress + ', ' + address.Place
    }

    render() {

        return (
            <div className={classNames('form-container', {active: this.props.currentStep === this.props.step})}>
                <div className="section-form-title">
                    <h4>Your address</h4>
                    <p className="lead">This will NOT affect your credit score</p>
                </div>
                <div className="row row-equal">
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['postcode']}>
                            <label>Postcode</label>
                            <div className="form-postcode">
                                <input ref={this.props.createInputRef.bind(null, 'postcode')} type="text" defaultValue={this.state.selectedAddress.postcode}
                                       className="form-control"/>
                                <a href="" onClick={this.findAddresses.bind(this)} className="btn btn-sm btn-primary">Find address</a>
                            </div>
                        </InputWrapper>
                    </div>
                    {this.state.addresses.length > 0 &&  (
                        <div className="col col-xs-12 col-md-6">
                            <div className="form-group">
                                <label>Select address</label>
                                <select onChange={this.selectAddress.bind(this)} name="" className="form-control">
                                    <option value=''>Please select</option>
                                    {this.state.addresses.map(address => (
                                        <option key={address.Id} value={address.Id}>{this.formatAddress(address)}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    )}
                    <div className="col col-xs-12">
                        <div className="gap" style={{height: "50px;"}}></div>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['name_or_number']}>
                            <label>House name / number</label>
                            <input ref={this.props.createInputRef.bind(null, 'name_or_number')} type="text" defaultValue={this.state.selectedAddress.name_or_number}
                                   className="form-control"/>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['street']}>
                            <label>Street</label>
                            <input ref={this.props.createInputRef.bind(null, 'street')} type="text" defaultValue={this.state.selectedAddress.street}
                                   className="form-control"/>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['city']}>
                            <label>City / town</label>
                            <input ref={this.props.createInputRef.bind(null, 'city')} type="text" defaultValue={this.state.selectedAddress.city}
                                   className="form-control"/>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12 col-md-6">
                        <InputWrapper error={this.props.errors['county']}>
                            <label>County</label>
                            <input ref={this.props.createInputRef.bind(null, 'county')} type="text" defaultValue={this.state.selectedAddress.county}
                                   className="form-control"/>
                        </InputWrapper>
                    </div>
                    <div className="col col-xs-12">
                        <div className="form-group">
                            <label>Time at this address</label>
                            <div className="form-choice">
                                <InputWrapper containerClasses={"field"}
                                              error={this.props.errors['time_at_address_years']}>
                                    <select ref={this.props.createInputRef.bind(null, 'time_at_address_years')}
                                            className="form-control">
                                        <option>Years</option>
                                        <option>1</option>
                                        <option>2</option>
                                    </select>
                                </InputWrapper>
                                <InputWrapper containerClasses={"field"}
                                              error={this.props.errors['time_at_address_months']}>
                                    <select ref={this.props.createInputRef.bind(null, 'time_at_address_months')}
                                            className="form-control">
                                        <option>Months</option>
                                        <option>1</option>
                                        <option>2</option>
                                    </select>
                                </InputWrapper>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-footer">
                    <a onClick={this.props.previousStep} href="" className="btn btn-primary">Previous</a>
                    <a onClick={this.props.submit} href="" className="btn btn-default">Next</a>
                </div>
            </div>
        )
    }

}

const AddressFieldset = withFieldset(Address)

export default AddressFieldset

