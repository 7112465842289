import React from "react"
import {Link} from "gatsby"

import Layout from "../layouts/index"
import SEO from "../components/seo"
import {Router} from "@reach/router"
import SearchResults from "../components/section/search-results";
import LoanForm from "../components/forms/loan-form";

class SearchPage extends React.Component {

    getLoanData() {
        const location = this.props.location.state
        if (location && location.loanAmount && location.months) {
            return {loanAmount: location.loanAmount, months: location.months}
        }
    }

    render() {
        const loanData = this.getLoanData()
        return (
            <Layout location={this.props.location} reRender={true}>
                <SEO title="Search Results" bodyAttributes={{class: 'quote-page'}}/>
                <Router>
                    <SearchResults path="/search/" key={this.props.location.key} loanData={loanData}/>
                    <LoanForm path="/search/apply/" loanData={loanData} />
                </Router>
            </Layout>
        )
    }
}

export default SearchPage