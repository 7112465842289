import React from "react"
import qs from 'qs';
import getValue from "../../helpers/getValue";

const withFieldset = WrappedFieldset => {

    return class Fieldset extends React.Component {

        fields = {}

        constructor(props) {
            super(props);
            this.state = {errors: {}, loading: false}
        }

        createInputRef(name, input) {
            this.fields[name] = input
            if (input && this.props.name in this.props.data && name in this.props.data[this.props.name]) {
                input.defaultValue = this.props.data[this.props.name][name]
            }
        }

        getField(name) {
            return this.fields[name]
        }

        getValue(field) {
            return getValue(field)
        }

        getData() {
            let data = {}
            for (let name in this.fields) {
                let field = this.getField(name)
                if (!field) {
                    continue
                }
                data[name] = this.getValue(field)
            }
            return data
        }

        validate() {
            let data = {[this.props.name]: this.getData()}
            const res = fetch("http://loanedapi.clientstage.co.uk/lender/lead/validate?field_map=loaned_gatsby", {
                method: "POST",
                body: qs.stringify(data),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }).then(response => {
                return response.json()
            });
            return res
        }

        submit(e) {
            e.preventDefault()
            this.setState({errors: {}, loading: true})
            this.validate().then(response => {
                this.setState({loading: false})
                if (response.success) {
                    this.nextStep()
                } else if (response.error_fields && response.error_fields[this.props.name]) {
                    this.setState({errors: response.error_fields[this.props.name]})
                }
            })
        }

        nextStep() {
            this.props.setStep(this.props.step + 1)
        }

        previousStep(e) {
            e.preventDefault()
            if (this.props.step > 1) {
                this.props.setStep(this.props.step - 1)
            }
        }

        render() {
            return (
                <WrappedFieldset
                    errors={this.state.errors}
                    loading={this.state.loading}
                    createInputRef={this.createInputRef.bind(this)}
                    previousStep={this.previousStep.bind(this)}
                    submit={this.submit.bind(this)}
                    fields={this.fields}
                    {...this.props}
                />
            )
        }
    }
}

export default withFieldset

