import React from "react"
import {navigate} from "gatsby"
import {Redirect} from "@reach/router"
import qs from 'qs';
import LoanFieldset from "./fieldset/loan-fieldset";
import PersonalFieldset from "./fieldset/personal-fieldset"
import PropertyFieldset from "./fieldset/property-fieldset";
import EmploymentFieldset from "./fieldset/employment-fieldset";
import ExpenditureFieldset from "./fieldset/expenditure-fieldset";
import AddressFieldset from "./fieldset/address-fieldset";
import BankAccountFieldset from "./fieldset/bank-account-fieldset";
import Usps from "../partials/usps";
import AprText from "../partials/apr-text";

class LoanForm extends React.Component {

    steps = {}

    constructor(props) {
        super(props)
        this.state = {step: 1, data: {}, loading: false}
    }

    getCurrentPercentage() {
        const currentStep = this.state.step - 1
        if (currentStep === 0) {
            return 0
        }
        return (currentStep / Object.keys(this.steps).length) * 100
    }

    createStepRef(ref) {
        if (ref && ref.props.name) {
            this.steps[ref.props.name] = ref;
        }
    }

    setStep(step) {
        this.setState({'step': step})
    }

    getData() {
        let data = {}
        data['loan_amount'] = this.props.loanData.loanAmount
        data['months'] = this.props.loanData.months
        data['referring_site_url'] = window.location.origin
        data['test'] = 1
        for (let name in this.steps) {
            data[name] = this.steps[name].getData()
        }
        return data;
    }

    submitForm(e) {
        e.preventDefault()
        if (this.state.loading) {
            return
        }
        const data = this.getData()
        for (let fieldset in this.steps) {
            this.steps[fieldset].setState({errors: {}})
        }
        this.setState({loading: true})
        fetch("http://loanedapi.clientstage.co.uk/lender/lead/post?field_map=loaned_gatsby", {
            method: "POST",
            body: qs.stringify(data),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }).then(response => {
            return response.json()
        }).then(response => {
            this.setState({loading: false})
            if (response.success) {
                navigate('/results/', {
                    state: {lead: response.lead_id}
                })
            } else if (response.error_fields) {
                let focussed = false
                for (let fieldset in response.error_fields) {
                    this.steps[fieldset].setState({
                        errors: response.error_fields[fieldset]
                    })
                    if (!focussed) {
                        this.setState({
                            step: this.steps[fieldset].props.step
                        })
                        focussed = true
                    }
                }
            }
        })
    }

    outputFieldset(Fieldset, name, step, props) {
        return <Fieldset
            ref={this.createStepRef.bind(this)}
            name={name}
            step={step}
            data={this.state.data}
            currentStep={this.state.step}
            setStep={this.setStep.bind(this)}
            submitForm={this.submitForm.bind(this)}
            {...props}
        />
    }

    render() {
        if (!this.props.loanData) {
            return <Redirect to="/search/" noThrow/>
        }
        return (
            <div className="section section-form">
                <div className="container">
                    <div className="row">
                        <div className="col col-sm-4 col-md-3">
                            <div className="sidebar quote-form-sidebar">
                                <div className="section-dark box box-blue box-my-loaned box-my-loaned-result">
                                    <h5><i className="fas fa-lock"></i>My Loaned</h5>
                                    <p>
                                        We have found lenders willing to lend
                                        you <strong>£{this.props.loanData.loanAmount}</strong> over <strong>{this.props.loanData.months} months</strong>.
                                    </p>
                                    <p>Complete your details to unlock your personal rates.</p>
                                </div>
                               <Usps slider={true} />
                               <AprText/>
                            </div>
                        </div>
                        <div className="col col-sm-8 col-md-9 col-lg-8 col-lg-offset-1">
                            <div className="progress">
                                <div className="progress-inner"
                                     style={{width: this.getCurrentPercentage() + '%'}}></div>
                            </div>
                            <form onSubmit={this.submitForm.bind(this)}>
                                {this.outputFieldset(LoanFieldset, 'loan', 1)}
                                {this.outputFieldset(PersonalFieldset, 'personal', 2)}
                                {this.outputFieldset(PropertyFieldset, 'property', 3)}
                                {this.outputFieldset(AddressFieldset, 'address', 4)}
                                {this.outputFieldset(EmploymentFieldset, 'employment', 5)}
                                {this.outputFieldset(ExpenditureFieldset, 'expenditure', 6)}
                                {this.outputFieldset(BankAccountFieldset, 'bank', 7)}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoanForm